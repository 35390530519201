import { flattenTreeToMap } from '@src/app/utils';
import { loaderEnd, loaderStart } from '@src/helpers/loaderHelper';
import store from '@src/reduxStore/store';
import {
  selectCurrentThemeTreeWithVisibilityExceptions,
  selectTreeLoadingStatus,
} from '@src/reduxStore/theme/themeSelectors';
import { initCurrentTheme } from '@src/reduxStore/theme/themeSlice';
import { stateMatch } from 'redux-awaitable-state';

const meta = { disableUpdate: true };

const themeRoutes = [
  {
    name: 'theme',
    component: 'wsTheme',
    params: {
      source: null,
      themeSource: null,
      curricula: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      scrollTo: {
        value: null,
        squash: true,
        dynamic: true,
      },
    },
    resolve: {
      sTheme: ($stateParams, kovSentry, $location) => {
        'ngInject';

        const time = new Date();
        loaderStart();
        kovSentry.startLog('Load Pro Theme');
        store.dispatch(initCurrentTheme($stateParams.source));
        return stateMatch((state) => selectTreeLoadingStatus(state) === 'succeeded').then(() => {
          loaderEnd();
          kovSentry.endLog('Load Pro Theme', Date.now() - time < 10);
          kovSentry.endLog('load Pro');
          const theme = selectCurrentThemeTreeWithVisibilityExceptions(
            store.getState(),
            $stateParams.source
          );
          const page = flattenTreeToMap(theme, false).get($stateParams.source);
          if (page && !$location.path().toLowerCase().startsWith(page.pagePath.toLowerCase())) {
            $location.path(page.pagePath).replace();
            return Promise.reject(new Error(`Redirected to: ${page.pagePath}`));
          }
          return theme;
        });
      },
    },
    meta,
  },
  {
    name: 'theme.home1',
    component: 'wsThemeHome1',
    params: {
      source: null,
    },
    meta,
  },
  {
    name: 'theme.home2',
    component: 'wsThemeHome2',
    params: {
      source: null,
    },
    meta,
  },
  {
    name: 'theme.home3',
    component: 'wsThemeHome3',
    params: {
      source: null,
    },
    meta,
  },
  {
    name: 'theme.detail',
    component: 'wsThemeDetail',
    params: {
      source: null,
      style: null,
      requirements: [],
      q: null,
      scrollTo: {
        value: null,
        squash: true,
        dynamic: true,
        inherit: false,
      },
      zillItem: null,
      sortingType: null,
      ageRangeFrom: null,
      ageRangeTo: null,
    },
    meta,
  },
  {
    name: 'theme.news',
    component: 'wsLocalNewsDatabank',
    params: {
      q: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      van: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      tot: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      page: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      curricula: {
        value: [],
        dynamic: true,
        array: true,
        inherit: false,
      },
      order: { value: null, dynamic: true, inherit: false },
    },
    meta,
  },
  {
    name: 'theme.training',
    component: 'wsThemeTrainings',
    params: {
      q: { dynamic: true, inherit: false },
      orderby: { dynamic: true, inherit: false },
      limit: { dynamic: true, inherit: false },
      trainingTypes: { dynamic: true, inherit: false },
      lat: { dynamic: true, inherit: false },
      lon: { dynamic: true, inherit: false },
      distanceInKm: { dynamic: true, inherit: false },
      mainstructuresOuTypeCombinations: { dynamic: true, inherit: false },
      coverage: { dynamic: true, inherit: false },
      positions: { dynamic: true, inherit: false },
      dateFrom: { dynamic: true, inherit: false },
      dateTo: { dynamic: true, inherit: false },
      attendanceType: { dynamic: true, inherit: false },
      /** NEEDED THE ONES BELOW LOCAL TRAININGS¿ */
      curricula: {
        value: [],
        array: true,
        dynamic: true,
      },
      menuItem: { dynamic: true, inherit: false },
    },
    meta,
  },
  {
    name: 'theme.search',
    params: {
      source: null,
      q: null,
      searchType: null,
      limit: null,
    },
    component: 'wsSearch',
    meta,
  },
  {
    name: 'theme.database',
    component: 'wsDatabase',
    params: {
      source: null,
      facets: [],
      requirements: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      curricula: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      q: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      sortingType: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      zillItem: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      ageRangeFrom: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      ageRangeTo: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      order: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      limit: {
        value: null,
        dynamic: true,
        inherit: false,
      },
    },
    meta,
  },
  {
    name: 'theme.databaseItem',
    component: 'wsDatabaseItem',
    params: {
      source: null,
      requirements: [],
      q: null,
      sortingType: null,
      zillItem: null,
      ageRangeFrom: null,
      ageRangeTo: null,
      scrollTo: null,
    },
    meta,
  },
  {
    name: 'theme.temp',
    component: 'wsTemp',
    params: {
      source: null,
    },
    meta,
  },
  {
    name: 'theme.faq',
    component: 'wsThemeFaq',
    params: {
      source: null,
      faqGroup: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      q: {
        value: null,
        dynamic: true,
        inherit: false,
      },
    },
    meta,
  },
  {
    name: 'theme.download',
    component: 'wsThemeDownload',
    params: {
      source: null,
      thumbs: true,
      facets: [],
      requirements: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      q: {
        value: null,
        dynamic: true,
        inherit: false,
      },
    },
    meta,
  },
  {
    name: 'theme.downloadNoThumb',
    component: 'wsThemeDownload',
    params: {
      source: null,
      thumbs: false,
      facets: [],
      requirements: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      q: {
        value: null,
        dynamic: true,
        inherit: false,
      },
    },
    meta,
  },
  {
    name: 'theme.blog',
    component: 'wsThemeBlog',
    params: {
      source: null,
      style: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      requirements: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      q: {
        value: null,
        dynamic: true,
        inherit: false,
      },
      limit: { dynamic: true },
      facets: [],
    },
    meta,
  },
  {
    name: 'theme.blogItem',
    component: 'wsThemeBlogItem',
    params: {
      source: null,
      style: null,
      requirements: [],
      q: null,
      limit: null,
      scrollTo: null,
    },
    meta,
  },
  { name: 'theme.static', component: 'wsThemeStatic', meta },
  {
    name: 'vakkenpagina',
    component: 'wsVakkenpagina',
    meta,
    params: {
      tab: { dynamic: true, inherit: false },
      q: { dynamic: true, inherit: false },
      firstGradeExpandedSections: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      secondGradeExpandedSections: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      thirdGradeExpandedSections: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
      oldStructureExpandedSections: {
        value: [],
        array: true,
        dynamic: true,
        inherit: false,
      },
    },
  },
];

export default themeRoutes;
