/* eslint-disable*/

import {
  getSitemapItemFromSource,
  getAllSitemapItemsFromType,
  getValidUrl,
  extendSitemapRedirects,
} from '@src/services/sitemapService';
import { isPreview } from '@src/helpers/previewHelper';
import { loaderEnd } from '@src/helpers/loaderHelper';

const service = (settings, cachedWebsitesApi, $location, md5, $anchorScroll, $state, $timeout) => {
  'ngInject';

  const that = {};
  const urlPathArray = [];

  that.transitionTo404 = (location = false) => {
    loaderEnd();
    $state.transitionTo(
      '404',
      {},
      {
        location,
      }
    );
  };

  /**
   * This function converts a content href into a valid url
   * @param  {[href]} href Content href parameter: /content/{GUID}
   * @return {[url]}
   */
  that.getValidUrl = (href) => {
    // we moved this method to `sitemapService` because we need to use outside of the "angular context"
    return getValidUrl(href);
  };

  /**
   * This function compiles a webpage object based on a href, using the info both
   * from sitemap api and extended with specific attributes from the cached items (eager or full)
   * @param  {[contentHref]} href Content href parameter: /content/{GUID}
   * @return {[object]}
   */
  that.getWebpageInfoFromHref = (href) => {
    const sitemapItem = getSitemapItemFromSource(href);

    if (!sitemapItem) return null;

    const webpageInfo = {
      ...sitemapItem,
      key: href.split('/').pop(),
      facets: [],
      multipleAlternativeMenus: false,
    };

    const webpageExtendedVersion = that.extendedWebpages.get(href);

    if (webpageExtendedVersion) {
      return {
        ...webpageInfo,
        hasExtendedVersion: true,
        facets: webpageExtendedVersion.facets,
        banner: webpageExtendedVersion.banner,
        multipleAlternativeMenus: webpageExtendedVersion.multipleAlternativeMenus,
      };
    }

    return webpageInfo;
  };

  // We get this element from the "extendedWebpages" because it is not in the sitemap
  // When leerplansites hits production the Global Database will be available in the sitemap (in principle)
  that.getGlobalDatabase = () => that.getWebpageInfoFromHref(settings.proGlobalDatabase.href);

  /**
   * This method get some specific types of webpages only when the "application starts" and saved them in a cache. That informtion is needed to fulfill some missing data that the sitemap has at this moment (such as facets data)
   * @param {*} contentRootHref
   * @returns
   */
  that.getNeededWebpagesFromApi = async () => {
    if (that.extendedWebpages.size === 0) {
      try {
        const webpages = await cachedWebsitesApi.getAll(
          '/web/pages',
          {
            // 'THEME_HOME_TEXT', 'THEME_HOME_PICTURE', 'THEME_HOME_FULL' => Only needed for being able to get the "banner" of those webpages and be ble to show it on curriculum support sites (introduced in leerplansites)
            // MINI_DATABASE,BLOG,DOWNLOAD_PAGE => Only needed for being able to get the facets on those pages
            // FALLBACK_MENU_LEVEL_2 => Only needed for the webpages that have 'multipleAlternativeMenus'
            typeIn:
              'MINI_DATABASE,BLOG,DOWNLOAD_PAGE,FALLBACK_MENU_LEVEL_2,THEME_HOME_TEXT,THEME_HOME_PICTURE,THEME_HOME_FULL',
            limit: 5000,
            website: settings.website,
          },
          {
            headers: isPreview()
              ? {
                  'VSKO-Resource-Hash': new Date().toISOString(),
                }
              : {},
          }
        );
        that.addToExtendedWebpages(webpages);
      } catch (e) {
        console.error('routerService:getNeededWebpagesFromApi failed with: ', { error: e });
      }
    }
  };

  // This Map includes ONLY the webpages for which we need extra info from the API when we get them from the sitemap, such as facets info (which is not coming from the sitemap by now)
  that.extendedWebpages = new Map();

  that.isProWebpage = (websiteHref) => {
    const proWebsiteHref = settings.website;
    return proWebsiteHref === websiteHref;
  };

  that.addToExtendedWebpages = (webpages) => {
    const proWebpages = webpages.filter((webpage) => that.isProWebpage(webpage.website.href));
    proWebpages.forEach((webpage) => {
      // oldLocations for all types of webpages are managed in the sitemap
      // in exception for the "FALLBACK_MENU_LEVEL_2" type
      if (webpage.oldLocations?.length && webpage.type === 'FALLBACK_MENU_LEVEL_2') {
        const proOldLocations = webpage.oldLocations.filter(
          (oldLoc) => oldLoc.website.href === settings.website
        );
        if (proOldLocations.length) extendSitemapRedirects(proOldLocations, webpage.path);
      }
      that.extendedWebpages.set(webpage.source.href, that.webpageToWebpageInfo(webpage));
    });
  };

  that.webpageToWebpageInfo = (websiteItem) => ({
    key: websiteItem.key,
    path: websiteItem.path,
    source: websiteItem.source.href,
    root: websiteItem.externalReferences && websiteItem.externalReferences[0],
    type: websiteItem.type,
    facets: websiteItem.options ? websiteItem.options.facets : [],
    multipleAlternativeMenus: !!(
      websiteItem.options && websiteItem.options.multipleAlternativeMenus
    ),
    banner:
      websiteItem.options && websiteItem.options.banner && websiteItem.options.banner.highlight
        ? websiteItem.options.banner
        : undefined,
  });

  that.generateHashForScrollTo = (key) => `_${md5.createHash(key).slice(0, 8)}`;

  that.scrollTo = (anchor, yOffset = 0) => {
    $anchorScroll.yOffset = yOffset;
    $anchorScroll(anchor);
  };

  that.checkPreviousPaths = (pathArray) => {
    let path = pathArray[0];
    urlPathArray.forEach((urlPath) => {
      if (pathArray.some((pathItem) => pathItem.parent.path === urlPath)) {
        path = pathArray.find((pathItem) => pathItem.parent.path === urlPath);
      }
    });
    return path;
  };

  that.findWebConfigurationByType = (type) => {
    const sitemapItem = getAllSitemapItemsFromType(type);
    return (sitemapItem && sitemapItem[0]) || null;
  };

  // replacing the original wsDatabaseItem function. This needs to be replaced by react router
  that.goToPath = (path, params) => {
    $timeout(() => {
      $location.path(path).search(params);
    });
  };
  return that;
};

angular.module('services').factory('routerService', service);
