import { cachedContentApi } from '@src/app/apiConfig';

/**
 * This function returns the locations in SAM for the given SAM Organisational Units hrefs
 * @param {Array.<string>} SAMOusHrefs
 * @returns {Promise<Array<*>>}
 */
export const getSAMOusLocations = async (SAMOusHrefs) => {
  if (SAMOusHrefs.length) {
    cachedContentApi.getAll('/sam/organisationalunits/locations', {
      organisationalUnit: SAMOusHrefs.join(','),
      expand: 'results.physicalLocation',
      endDateAfter: new Date().toISOString().split('T')[0],
    });
  }
  return [];
};

/**
 * This function returns the locations in VOS for the given VOS Organisational Units hrefs
 * @param {Array.<string>} VOSOusHrefs
 * @returns {Promise<Array<*>>}
 */
export const getVOSOusLocations = async (VOSOusHrefs) => {
  if (VOSOusHrefs.length) {
    return cachedContentApi.getAll('/schoollocations', {
      hrefs: VOSOusHrefs.join(','),
    });
  }
  return [];
};

/**
 * This function returns the SAM organisationalUnits for the given SAM organisationalUnits hrefs
 * @param {Array.<string>} SAMOusHrefs
 * @returns {Promise<Array<*>>}
 */
export const getSAMOus = async (SAMOusHrefs) => {
  if (SAMOusHrefs.length) {
    return cachedContentApi.getAll('/sam/organisationalunits', {
      hrefs: SAMOusHrefs.join(','),
    });
  }
  return [];
};

/**
 * This function returns the VOS organisationalUnits for the given organisationalUnits hrefs
 * @param {Array.<string>} VOSOusHrefs
 * @returns {Promise<Array<*>>}
 */
export const getVOSOus = async (VOSOusHrefs) => {
  if (VOSOusHrefs.length) {
    return cachedContentApi.getAll('/organisationalunits', {
      hrefs: VOSOusHrefs.join(','),
      expand: 'FULL',
    });
  }
  return [];
};

/**
 * This function returns the VOS equivalent organisationalUnits for the given SAM organizationalUnits hrefs
 * @param {Array.<string>} SAMOusHrefs
 * @returns {Promise.Array<*>}
 */
export const getVOSEquivalentOuToSAMOu = async (SAMOusHrefs) => {
  if (SAMOusHrefs.length) {
    return cachedContentApi
      .getAll('/sam/organisationalunits/externalidentifiers', {
        organisationalUnitContains: SAMOusHrefs.join('').split('/').pop(),
        type: 'VOS_PERMALINK',
      })
      .then((vosPermalinks) =>
        vosPermalinks.length
          ? getVOSOus(vosPermalinks.map((vosPermalink) => vosPermalink.value))
          : []
      );
  }
  return [];
};
/**
 * This function returns the Dioceses hrefs which belong to the given zipcodes
 * @param {Array.<string>} zipcodes
 * @returns {Promise.Array<string>}
 */
export const getDiocesesHrefsFromZipcodes = async (zipcodes) => {
  if (zipcodes.length === 0) return [];

  const neighbourhoodsHrefs = await cachedContentApi
    .getAll('/subcities', {
      zipcodes: zipcodes.join(','),
    })
    .then((neighbourhoodsResp) =>
      neighbourhoodsResp.map((neighbourhood) => neighbourhood.$$meta.permalink)
    );

  const cities = await cachedContentApi.getAll('/cities', {
    limit: 500,
    expand: 'FULL',
  });

  return cities.reduce((accDiocesesHrefs, city) => {
    const cityDioceseHref = city.diocese?.href;

    if (!cityDioceseHref) return accDiocesesHrefs;

    const cityNeighbourhoodsHrefs = city.subCities?.map((a) => a.href) || [];
    // if the cityNeighbourhoods matches any of the given neighbourhoods we add the diocese to the list
    if (
      cityNeighbourhoodsHrefs.length &&
      cityNeighbourhoodsHrefs.some((item) => neighbourhoodsHrefs.includes(item)) &&
      !accDiocesesHrefs.includes(cityDioceseHref)
    ) {
      accDiocesesHrefs.push(cityDioceseHref);
    }

    return accDiocesesHrefs;
  }, []);
};

/**
 * This functions return the educational programmes for the given SAM organisational units hrefs
 * @param {Array.<string>} SAMOusHrefs
 * @returns {Promise.Array<*>}
 */
export const getSAMOusEducationalProgrammes = async (SAMOusHrefs) => {
  if (SAMOusHrefs.length) {
    return cachedContentApi.getAll('/sam/educationalprogrammedetails', {
      organisationalUnit: SAMOusHrefs.join(','),
      expand: 'results.ag',
      hasStudyProgramme: 'false',
    });
  }
  return [];
};
