import { buildTreeFromHref } from '@src/helpers/utils';
import { loaderEnd, loaderStart } from '@src/helpers/loaderHelper';
import { getUserAccess } from '@src/reduxStore/user/userSelectors';
import store from '@src/reduxStore/store';
import { stateMatch } from 'redux-awaitable-state';
import { selectCurrentTeaserOrNews } from '@src/reduxStore/news/newsSelectors';
import { loadThemePaths, loadCurrentTeaserOrNews } from '@src/reduxStore/news/newsSlice';
import { initCurrentTheme } from '@src/reduxStore/theme/themeSlice';
import {
  selectCurrentThemeTree,
  selectItemInCurrentThemeTree,
  selectTreeLoadingStatus,
} from '@src/reduxStore/theme/themeSelectors';
import * as settings from '@src/config/settings';
import {
  selectNavigationItems,
  selectNavigationLevel,
} from '@src/reduxStore/navigation/navigationSelectors';
import { navigationActions } from '@src/reduxStore/navigation/navigationSlice';
import { getUserStoredSelectedTabHref } from '@src/helpers/navigationHelper';

const meta = { disableUpdate: true };

const generalRoutes = [
  /* Navigation */
  {
    name: 'home',
    component: 'wsNavigationFirstLevel',
    cache: false,
    meta,
  },
  {
    name: 'navigationSecondLevel',
    component: 'wsNavigationSecondLevel',
    params: {
      source: null,
      tab: {
        dynamic: true,
      },
    },
    meta,
    resolve: {
      sMenu: ($stateParams) => {
        'ngInject';

        return stateMatch((state) => selectNavigationItems(state).length > 0).then(() => {
          const menuItem = selectNavigationLevel(store.getState(), $stateParams.source);

          // if we are in a theme of type "multipleAlternativeMenus" and we have a tab in the url we set it on the store before initializing the view
          if (menuItem.multipleAlternativeMenus) {
            const availableTabs = menuItem.children;
            const userStoredSelectedTabHref = getUserStoredSelectedTabHref(
              availableTabs.map((avalTab) => avalTab.href)
            );

            // if we have a tab on the url we need to respect that and load that tab
            if ($stateParams.tab?.length > 0) {
              const subtabItemHref = availableTabs.find(
                (tab) => tab.title === $stateParams.tab
              )?.href;

              if (subtabItemHref)
                store.dispatch(navigationActions.setCurrentSubTabHref(subtabItemHref));
            } else if (userStoredSelectedTabHref) {
              // if we don't have a tab in the url but we have a tab saved in the localStorage we load that one as the first one
              store.dispatch(navigationActions.setCurrentSubTabHref(userStoredSelectedTabHref));
            }
          }
          return menuItem;
        });
      },
    },
  },
  {
    name: 'navigationThirdLevel',
    component: 'wsNavigationThirdLevel',
    params: {
      source: null,
    },
    meta,
  },

  /* News */
  {
    name: 'newsDatabank',
    component: 'wsGlobalNewsDatabank',
    params: {
      firstLevel: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      tabLevel: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      secondLevel: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      thirdLevel: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      q: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      van: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      tot: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      page: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      curricula: {
        value: [],
        inherit: false,
        array: true,
        dynamic: true,
      },
      order: {
        inherit: false,
        value: null,
        dynamic: true,
      },
      menuItem: {
        inherit: false,
        value: null,
        dynamic: true,
      },
    },
    meta,
  },
  {
    name: 'newsDetail',
    url: null,
    component: 'wsGlobalNewsDetail',
    params: {
      source: null,
    },
    meta,
    resolve: {
      sNew: ($stateParams) => {
        'ngInject';

        loaderStart();
        store.dispatch(loadCurrentTeaserOrNews($stateParams.source));
        return stateMatch((state) => selectCurrentTeaserOrNews(state)).then(() => {
          loaderEnd();
          store.dispatch(loadThemePaths());
          return selectCurrentTeaserOrNews(store.getState());
        });
      },
    },
  },
  {
    name: 'globalDatabaseItem',
    url: null,
    component: 'wsDatabaseItem',
    params: {
      source: null,
      refDB: null,
    },
    meta,
    resolve: {
      sCurrent: ($stateParams) => {
        'ngInject';

        return stateMatch((state) => getUserAccess(state)?.length > 0).then(async () => {
          const itemTree = await buildTreeFromHref(
            $stateParams.source,
            getUserAccess(store.getState())
          );
          if (itemTree?.type === 'SHARED_MINI_DATABASE_ITEM') {
            let parent = {
              title: '',
              pagePath: settings.proGlobalDatabase.url,
              children: [],
              facets: [],
            };

            if ($stateParams.refDB) {
              await stateMatch(
                (state) =>
                  selectTreeLoadingStatus(state) === 'succeeded' && selectCurrentThemeTree(state)
              );

              const database = selectItemInCurrentThemeTree(
                store.getState(),
                `/content/${$stateParams.refDB}`
              );

              if (database) {
                parent = {
                  // user came from a standalone database
                  title: database.title,
                  pagePath: database.pagePath,
                  children: [itemTree],
                  facets: database.facets,
                };
              }
            }

            return {
              ...itemTree,
              parent,
            };
          }

          return itemTree;
        });
      },
      sTheme: ($stateParams) => {
        'ngInject';

        if ($stateParams.refDB) {
          store.dispatch(initCurrentTheme(`/content/${$stateParams.refDB}`));
          return stateMatch((state) => selectTreeLoadingStatus(state) === 'succeeded').then(() =>
            selectCurrentThemeTree(store.getState())
          );
        }

        return undefined;
      },
    },
  },

  /* Global database */
  {
    name: 'globalDatabank',
    component: 'wsDatabase',
    params: {
      source: null,
      facets: [],
      requirements: {
        value: [],
        inherit: false,
        array: true,
        dynamic: true,
      },
      curricula: {
        value: [],
        inherit: false,
        array: true,
        dynamic: true,
      },
      q: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      sortingType: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      zillItem: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      ageRangeFrom: {
        value: null,
        inherit: false,
        dynamic: true,
      },
      ageRangeTo: {
        value: null,
        inherit: false,
        dynamic: true,
      },
    },
    meta,
  },

  /* Trainings */
  {
    name: 'trainings',
    component: 'wsTrainings',
    params: {
      q: { dynamic: true, inherit: false },
      orderby: { dynamic: true, inherit: false },
      limit: { dynamic: true, inherit: false },
      trainingTypes: { dynamic: true, inherit: false },
      lat: { dynamic: true, inherit: false },
      lon: { dynamic: true, inherit: false },
      distanceInKm: { dynamic: true, inherit: false },
      mainstructuresOuTypeCombinations: { dynamic: true, inherit: false },
      coverage: { dynamic: true, inherit: false },
      positions: { dynamic: true, inherit: false },
      menuItem: { dynamic: true, inherit: false },
      curricula: {
        value: [],
        array: true,
        dynamic: true,
      },
      attendanceType: { dynamic: true, inherit: false },
      dateFrom: { dynamic: true, inherit: false },
      dateTo: { dynamic: true, inherit: false },
    },
    meta,
  },

  /* Search */
  {
    name: 'search',
    component: 'wsSearch',
    params: {
      scope: { dynamic: true, inherit: false },
      q: { dynamic: true, inherit: false },
      types: { dynamic: true, inherit: false },
      limit: { dynamic: true, inherit: false },
      curricula: {
        value: [],
        array: true,
        dynamic: true,
      },
    },
    meta,
    resolve: {
      sNavigationTree: (navigationService) => {
        'ngInject';

        return navigationService.getNavigationTreeAsync();
      },
    },
  },
  /* Secure Access */

  {
    name: 'afgeschermd-1',
    url: '/niet-aangemeld',
    params: {
      directDownload: null,
      theme: null,
    },
    component: 'wsNotLoggedIn',
    meta,
    resolve: {
      sTheme: ($stateParams) => {
        'ngInject';

        return $stateParams.theme;
      },
    },
  },
  {
    name: 'afgeschermd-2',
    url: '/geen-toegang',
    params: {
      directDownload: null,
      theme: null,
    },
    resolve: {
      sTheme: ($stateParams) => {
        'ngInject';

        return $stateParams.theme;
      },
    },
    component: 'wsNotPermitted',
    meta,
  },
  {
    name: 'afgeschermd-3',
    url: '/geen-toegang-strikt',
    params: {
      directDownload: null,
      theme: null,
    },
    component: 'wsNotPermitted',
    meta,
    resolve: {
      sTheme: ($stateParams) => {
        'ngInject';

        return $stateParams.theme;
      },
    },
  },
  {
    name: 'afgeschermd-4',
    url: '/Informatie-voor-besturen',
    params: {
      directDownload: null,
      theme: null,
    },
    component: 'wsNotPermitted',
    meta,
    resolve: {
      sTheme: ($stateParams) => {
        'ngInject';

        return $stateParams.theme;
      },
    },
  },

  /* Errors */
  {
    name: '404',
    url: '/404',
    component: 'ws404',
    meta,
  },
  {
    name: '500',
    url: '/500',
    component: 'ws500',
    meta,
  },
];

export default generalRoutes;
